<template>
  <div class="classroom">
    <div class="top"><i class="el-icon-monitor" :style="{ fontWeight: 'bold' ,padding:'0 10px'}" ></i>{{ this.$route.query.classname }}</div>
    <div class="content">
      <div class="nav">
        <div class="title">课程目录</div>
        <div class="chapter" v-for="(item, index) in courses" :key="index" @click="change(index)">
          <div class="icon"><i class="el-icon-view" :class="index==chapter?'blue_color':''"></i></div> 
          <div class="name" :class="index==chapter?'blue_color':''">{{ item.chapter_num }}.{{ item.chapter_name }}</div>
        </div>
      </div>
      <div class="right_body">
      <div class="course_name">{{ courses[chapter].chapter_num }}.{{ courses[chapter].chapter_name }}</div>
        <video :src="courses[chapter].video_url" controls></video>
      </div>
    </div>
  </div>
</template>
  
  <script>

export default {
  name: "Home",
  
  data() {
    return {
      courses: [{
        chapter_name: "",
        chapter_num: 1,
        course_name: "",
        video_url: ""
      }],
      chapter: 0,
    };
  },
  computed: {},
  async created() {
    let res = await this.$axios("getclassroom", {
      classname: this.$route.query.classname,
    });
    console.log(res);
    this.courses = res.result;
  },
  methods:{
    change(index){
      this.chapter=index
    }
  }
};
</script>
  
  <style lang="less" scoped>
.classroom {
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;

  .top {
    width: 100%;
    height: 10vh;
    background-color: #fff;
    font-weight: bold;
    font-size: 22px;
  }
  .content {
    display: flex;
    justify-content: left;
    height: 90vh;
    .nav {
      width: 15%;
      height: 100%;
      overflow-y: scroll;
      margin-left: 15%;
      background-color: #fff;
      .title {
        font-size: 22px;
        font-weight: 700;
        position: relative;
        margin-left: 10px;
        margin-bottom: 3vh;
        &::before {
          content: "";
          height: 25px;
          border: 3px solid;
          display: inline-block;
          color: #2497e7;
          position: absolute;
          left: -5px;
          top: 0;
        }
      }
      .chapter{
        width: 90%;
        height: 5vh;
        margin-left: 10%;
        display: flex;
        justify-content: left;
        box-sizing: border-box;
        padding: 1vh 0;
        cursor: pointer;
        .icon{
          margin-top: 0.3vh;
          display: inline-block;
        }
        .name{
          font-size: 16px;
          font-weight: 500;
          margin-left: 10%;
          display: inline-block;
        }
      }
    }
    .right_body {
      width: 60%;
      height: 100%;
      padding: 5%;
      box-sizing: border-box;
      background-color: #fff;
      .course_name{
        font-size: 22px;
        font-weight: 700;
        position: relative;
        margin-left: 10px;
        margin-bottom: 3vh;
        &::before {
          content: "";
          height: 25px;
          border: 3px solid;
          display: inline-block;
          color: #2497e7;
          position: absolute;
          left: -5px;
          top: 0;
        }
      }
      video{
        width: 100%;
      }
    }
  }
}
/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #49b1f5;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #dbeffd;
  border-radius: 32px;
}
.blue_color{
  color: #2497e7;
}
</style>